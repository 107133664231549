/*-------
  Footer
-------*/
footer {
  min-height: 75vh;
  width: 100vw;
  background-color: black;
  color: white;

  position: relative;
  font-size: var(--content-font-size);
}
.contactMe {
  padding: 3rem 0;
}
.contactMe a {
  text-decoration: none;
  color: var(--main-white-color);
  font-family: var(--content-font-family);
  font-weight: 700;
  text-decoration: underline;
}
.links {
  width: 91vw;
  height: 100%;
  margin: auto;
}
.horizontalLine {
  background-color: var(--main-white-color);
  width: 100%;
  height: 0.3vh;
  margin: auto;
  border: 10%;
}
.links ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  list-style-type: none;

  padding-top: 1rem;
}
.links ul li:nth-child(1) {
  padding-left: 0;
}
.links ul li {
  padding-left: 1.1rem;
}
.links ul li a {
  text-decoration: none;
  color: var(--main-white-color);
}

/* For the vertical lines between footer links */
.links ul li:not(:last-child)::after {
  content: "|";
  color: white;
  padding-left: 2rem;
}
.links ul li:first-child::after {
  padding-left: 2rem;
}

.permissionstext {
  user-select: none;
  position: absolute;
  left: 4.35%;
  bottom: 5%;

  display: flex;
  align-items: center;

  color: rgb(118, 118, 118);

  letter-spacing: 0.1rem;
  font-weight: 500;
  font-family: var(--content-font-family);
  font-size: 1.2vw;
}
.permissionstext h5 {
  padding-left: 1rem;
}
/* MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  footer {
    min-height: 50vh;
    font-size: var(--content-font-size-phone-view);
  }

  .permissionstext {
    user-select: none;

    position: absolute;
    left: 4.35%;
    bottom: 5%;

    display: flex;
    align-items: center;

    color: rgb(118, 118, 118);

    letter-spacing: 0.1rem;
    font-weight: 500;
    font-family: var(--content-font-family);
    font-size: 2.2vw;
  }

  /* For the vertical lines between footer links */
  .links ul li:not(:last-child)::after {
    content: "|";
    color: white;
    padding-left: 0.8rem;
  }
  .links ul li:first-child::after {
    padding-left: 0.8rem;
  }
}
@media only screen and (min-width: 1800px) {
  .horizontalLine {
    margin-top: 1.8rem;
  }
}
