/* FullScreenReveal.css */
.fullscreen-reveal {
  overflow-y: hidden;
  cursor: none;
  pointer-events: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;
}

.reveal-text {
  height: 100%;

  color: white;
  font-size: 2rem;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
}
