.fs-menu {
  overflow-y: hidden;
  scrollbar-width: none; /* For Firefox */

  cursor: none;
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 122;
  color: var(--main-white-color);
}

.fs-menu ul,
.fs-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: black;
  height: 100vh;
  list-style-type: none;
  max-height: 100vh;
  max-width: 100vw;
}

.fs-menu ul li,
.fs-ul li {
  width: 350px;
  margin: 1rem;
  /* padding: 1rem; */
  border: 2px solid black;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.linkButtons {
  height: 100%;
  width: 100%;
  background-color: black;
  padding: 1rem 1.8rem;
  text-decoration: none;
  color: black;
  cursor: none;
  border: 1px solid white;

  border-radius: 12px;
  color: white;
  transition: scale 350ms ease;

  font-family: var(--content-font-family);
  font-size: var(--content-font-size);
}

.linkButtons:hover {
  background: radial-gradient(
    circle,
    rgba(38, 38, 38, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: rgb(178, 178, 178);
  scale: 1.2;

  -webkit-filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
  filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
  color: black;
}

.closeBtn {
  position: inherit;
  top: 0;
  right: 0;
  width: 12rem;
  height: 12rem;
  padding: 1.3rem;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.closeBtn:hover {
  border: 2px solid red;
  transform: scale(0.55);
}

.crossStyles {
  width: 60%;
  height: 60%;
  color: var(--main-white-color);
}

.displayNone {
  display: none;
}

/* 
  COLORS - btn
*/
.glow-on-hover {
  cursor: none !important;
  width: 100%;
  height: 100%;
  padding: 1rem 1.8rem;
  color: #fff !important;
  background: #111;
  position: relative;
  z-index: 0;
  border-radius: 12px;
  border: 1px solid white;
  font-family: var(--content-font-family);
  font-size: var(--content-font-size);
  transition: scale 350ms ease;
}
.glow-on-hover:hover {
  background: radial-gradient(
    circle,
    rgba(38, 38, 38, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: rgb(178, 178, 178);
  scale: 1.1;

  -webkit-filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
  filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
  color: black;
}
.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1 !important; /* Continuous effect */
  border-radius: 12px;
}
.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 12px;
}
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .fs-menu ul li,
  .fs-ul li {
    width: 340px;
    margin: 0.8rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .linkButtons,
  .glow-on-hover {
    height: 100%;
    width: 100%;
    background-color: black;
    color: black;
    padding: 1rem 1.4rem;
    text-decoration: none;
    cursor: none;
    border: 2px solid white;
    border-radius: 12px;
    color: white;
    transition: scale 350ms ease;
    font-family: var(--content-font-family);
    font-size: 2.2vh;
  }

  .linkButtons:hover,
  .glow-on-hover:hover {
    /* Ensure hover effect consistency */
    background: radial-gradient(
      circle,
      rgba(38, 38, 38, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    background: rgb(178, 178, 178);
    scale: 1.1;
    -webkit-filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
    filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
    color: black;
  }

  .closeBtn {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
  }
}

@media only screen and (min-width: 1255px) {
  .linkButtons,
  .glow-on-hover {
    height: 100%;
    width: 180% !important;
    /* width: 100%; */
    background-color: black;
    padding: 0.8rem 1.8rem;
    text-decoration: none;
    color: black;
    cursor: none;
    border: 1px solid white;
    /* border: 10px solid red; */

    border-radius: 12px;
    color: white;
    transition: scale 350ms ease;

    font-family: var(--content-font-family);
    font-size: var(--content-font-size);
  }
}
