@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oleo+Script&family=Poppins:wght@300&family=Roboto:wght@100;400&display=swap");

/* Hides scrollbar: */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-margin-top: 103px;
}
body {
  width: 100%;
  overflow-x: hidden;
}
/*    installing a font
      https://stackoverflow.com/questions/41676054/how-to-add-fonts-to-create-react-app-based-projects
*/

:root {
  --main-white-color: rgb(239, 239, 239);
  /* --content-font-family: HelveticaNowText-Light; */
  --content-font-family: "Poppins";

  --content-font-size: 1.8vw;
  --content-font-size-phone-view: 3.4vw;
}
@font-face {
  font-family: "Elastic";
  src: local("Elastic"), url(./fonts/Elastic.ttf) format("truetype");
}

@font-face {
  font-family: "Helvitica";
  src: local("Helvitica"), url(./fonts/Helvitica.ttf) format("truetype");
}
@font-face {
  /*
    font downloaded from: https://fontshub.pro/font/helvetica-now-text-download
  */
  font-family: "HelveticaNowText-Light";
  src: local("HelveticaNowText-Light"),
    url(./fonts/HelveticaNowText-Light.ttf) format("truetype");
}
/*
      Cursor
*/
.cursor {
  background-color: var(--main-white-color);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: fixed;
  top: 0%;
  left: 0%;
  pointer-events: none;

  z-index: 125;
}

/***************************

    GLOBAL styles ends here

***************************/



article {
  min-height: 110vh;
  background-color: var(--main-white-color);
  /* pointer-events: none; */
  cursor: none;
}

.homepage {
  user-select: none;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  cursor: none;
}

/* ---------- 1st section ------------ */
.landingSection {
  width: 100%;
  height: 100%;

  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  color: var(--main-white-color);
}

.landingTitle {
  width: 50vw;
  height: 100%;
  font-family: "Poppins", sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;
}
.landingText {
  font-size: 3.8vw;
}
.portraitImg {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0rem;
  width: 50vw;
  object-fit: cover;
  filter: grayscale(10%) brightness(100%);
  z-index: 0;
}

.selfportrait {
  width: 50%;
  height: 50%;
  object-fit: cover;
  object-position: center;
}

.largeText {
  font-size: var(--content-font-size);
  font-family: var(--content-font-family);
  font-weight: normal;

  /* border: 2px solid black; */
  text-align: left;
  padding: 2rem 1.8rem;
  /* padding-left: 2rem; */
}

.rodal__section {
  cursor: none;
  background-color: rgba(90, 90, 90, 0.73);
  width: 100vw;
  height: 100vh;
  z-index: 120;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
}

.rodal__parent {
  color: black;
  border-radius: 20px;
  overflow: hidden;
  user-select: none;
}

.rodal__subsection {
  width: 100%;
  height: auto;
  background-image: linear-gradient(
    to right top,
    #ffffff,
    #f6f6f6,
    #ededed,
    #e4e4e4,
    #dbdbdb
  );
  color: black;
  padding: 1.8rem 5.7rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.rodal__description {
  padding: 2rem 2.5rem;
}

.rodal__close-button {
  display: inline-block;
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.1vw;
  padding: 1.5rem;
  color: rgba(252, 11, 11, 0.571);
}

.rodal__close-button:hover {
  color: red;
}

.rodal__project-link {
  cursor: none;
  text-decoration: none;
}

.rodal__project-link:hover {
  color: blue;
  text-decoration: underline;
}

.rodal__flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.rodal__title {
  font-weight: 750;
  font-size: 2vw;
}

.rodal__tech-item {
  margin: 0.4rem 1.5rem;
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: blue;
  color: white;
  font-size: 16px;
  cursor: none;
  text-align: center;
  text-decoration: none;
}

/*
  Spinner Styles
*/
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
}

.loading-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;
}
.loading-text span {
  display: inline-block;
  margin: 0 5px;
  color: rgb(239, 239, 239);
  font-family: "Quattrocento Sans", sans-serif;
}
.loading-text span:nth-child(1) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0s infinite linear alternate;
  animation: blur-text 1.5s 0s infinite linear alternate;
}
.loading-text span:nth-child(2) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.2s infinite linear alternate;
  animation: blur-text 1.5s 0.2s infinite linear alternate;
}
.loading-text span:nth-child(3) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.4s infinite linear alternate;
  animation: blur-text 1.5s 0.4s infinite linear alternate;
}
.loading-text span:nth-child(4) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.6s infinite linear alternate;
  animation: blur-text 1.5s 0.6s infinite linear alternate;
}
.loading-text span:nth-child(5) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.8s infinite linear alternate;
  animation: blur-text 1.5s 0.8s infinite linear alternate;
}
.loading-text span:nth-child(6) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1s infinite linear alternate;
  animation: blur-text 1.5s 1s infinite linear alternate;
}
.loading-text span:nth-child(7) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}

@-webkit-keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

.intro-sliderClass {
  overflow-x: hidden;
  height: 110vh;
  padding: 40px;
  background-color: #efefef;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Space Grotesk", sans-serif;
  z-index: 10;
  width: 100%;
  gap: 40px;
  letter-spacing: -0.05em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 4.2rem;

  cursor: none;
  pointer-events: none;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .intro-sliderClass {
    overflow-x: hidden;
    height: 115vh;
    padding: 40px;
    background-color: #efefef;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Space Grotesk", sans-serif;
    z-index: 10;
    width: 100%;
    gap: 40px;
    letter-spacing: -0.05em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.2rem;
    cursor: none;
    pointer-events: none;
  }

  .AwshafContent {
    display: inline-block !important;
    line-height: 29px;
    font-family: "Architects Daughter", cursive;
    font-size: 5.5vw;
    letter-spacing: 1px;
    word-spacing: 5px;
    /* border: 2px solid red; */
    /* margin-top: 40px; */
    max-width: 30%;
  }
  .AwshafContent > svg {
    display: inline-block !important;
    /* background-color: orange; */
    transform: translateY(15px);
  }

  /* 
   *
   RODAL Styles:
   *
  */
  .rodal__parent {
    color: black;
    border-radius: 20px;
  }
  
  .rodal__subsection {
    width: 100%;
    height: auto;
    background-image: linear-gradient(
      to right top,
      #ffffff,
      #f6f6f6,
      #ededed,
      #e4e4e4,
      #dbdbdb
    );
    color: black;
    background-color: orange;

    padding: 1.8rem 5.7rem;
    flex-grow: 1;

    display: flex;
    flex-wrap: wrap;
    position: relative;

    background-color: orange;
  }
  .rodal__tech-stack {
    /* border: 2px solid red; */
    padding: 0.8rem;
  }
  .rodal__title {
    font-weight: 750;
    font-size: 4.8vw;
  }
  .rodal__subsection-title {
    /* border: 3px solid green; */
    padding: 2rem 1.8rem;
    padding-right: 3.5rem;
  }
  .rodal__tech-item {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 16px;
    cursor: none;
    text-align: center;
    text-decoration: none;

    display: flex;
    flex-wrap: wrap;
    margin: 0.3rem 0.2rem;
  }
  .rodal__close-button {
    display: inline-block;
    position: absolute;
    right: -5%;
    top: 50%;
    transform: translate(-50%, -50%);

    font-size: 5.5vw;
    padding: 1.5rem;
  }
  .rodal__description {
    padding: 2rem 2.5rem;
    /* border: 4px solid red; */
  }

  /* Turning off cursor: */
  .cursor {
    display: none;
  }

  .landingSection {
    justify-content: center;
    align-items: center;
    /* flex-direction: row; */
    flex-wrap: wrap;

    /* padding: 5.5rem; */
  }

  .landingTitle {
    width: 100vw;
    height: 40%;
    /* border:  red 3px solid; */

    text-align: center;
    margin-bottom: 1rem;
    /* border: 2px solid yellow; */
  }
  .landingText {
    font-size: 6vw;
  }
  .portraitImg {
    /* margin-bottom: 4rem; */
    transform: translateY(-75px);
    width: 100%;
    height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selfportrait {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
    object-position: center;

    /* padding: 1rem; */
    margin-top: -9rem;
    /* border: 2px solid red; */
    margin-bottom: 6rem;
    padding-bottom: 2rem;
  }

  .closeBtn {
    top: 0%;
    right: 3%;
    width: 6rem;
    height: 6rem;
    z-index: 30;
  }

  .permissionstext {
    font-size: 0.7rem;
  }

  .largeText {
    font-size: var(--content-font-size);
    font-family: var(--content-font-family);
    font-weight: normal;

    /* border: 2px solid black; */
    text-align: left;
    padding: 2rem 1.2rem;
    /* padding-left: 2rem; */
  }
  .largeText {
    font-size: var(--content-font-size-phone-view);
  }
}
