/* ---------- navbar ------------ */

nav {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0px; /* cuz 104= 6.5rem (navbar) */

  cursor: none;
  height: 104px;
  width: 100vw;

  /* position: fixed; */
  /* top: 0;
  left: 0; */

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 2.5rem;
  padding-right: 2.5rem;

  color: var(--main-white-color);
  background-color: black;
  z-index: 120;

  transition: all ease 1s;
}
.learnMoreSpecificStyles {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navOnWhite {
  color: black;
  background-color: var(--main-white-color);
}

.hambergerMenu {
  width: 3.1rem;
  height: 3.1rem;
}
.name-logo,
menu {
  box-sizing: border-box;
  padding: 2rem 2.1rem;
  margin: 1.2rem 1.6rem;
}
.menu {
  margin-right: 3%;
}
.name-logo {
  font-size: 1vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.firstName,
.lastName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  user-select: none;
}

/* P H O N E */
@media only screen and (max-width: 600px) {
  nav {
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top: 0px; /* cuz 104= 6.5rem (navbar) */

    cursor: none;
    height: 104px;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 0.2rem;
    padding-right: 2rem;

    color: var(--main-white-color);
    background-color: black;
    z-index: 120;

    transition: all ease 1s;
  }
  .name-logo {
    font-size: 2.5vw;
    margin-left: 0.8rem;
    padding-left: 1.3rem;
  }

  .hambergerMenu {
    width: 2.1rem;
    height: 2.1rem;
  }
}

/* T A B L E T */
@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .name-logo {
    font-size: 2vw;
    margin-left: 0.8rem;
    padding-left: 1.3rem;
  }
}
