* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

strong {
  font-weight: 900;
  text-decoration: underline;
}

.learnMoreParent {
  cursor: none;

  width: 100vw;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 4rem;
}

.secondSection {
  min-height: 100vh;
  width: 100%;
  color: black;
  padding-bottom: 5rem;

  background-color: var(--main-white-color);
  transition: all 1.3s ease;

  overflow-x: clip;
}

.paraContainers {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  background-color: white;

  min-height: 300vh;
}
.educationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(206, 206, 206);
  min-height: 60vh;
  z-index: 115;

  margin: 1rem 0;
}
.educationContainer > a {
  color: rgb(181, 30, 30);
  background-color: #CECECE;
  z-index: 115;
}
#schoolName{
  min-height: 12.5vh;
}
.sfulogo {
  width: 10rem;
  padding: 0;
  margin: 0;
  transform: translateY(6%);
}
.sfulogo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oleo+Script&family=Poppins:wght@300&family=Roboto:wght@100;400&display=swap");
.globalTextStyles {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;

}
.jobTitle {
  user-select: none;
  text-align: left;
  font-size: 5vw;

  position: sticky;
  position: -webkit-sticky; /* Safari */
  top:104px; /* cuz 104= 6.5rem (navbar) */

  background-color: white;
  width: 100%;

  height: 153px;
  z-index: 118;
}
.companyRow{
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top:257px;
  width: 100%;
  background-color: white;

  z-index: 119;
}

.company {
  font-size: 4vw;
  font-weight: 300;
  width: 100%;
  text-align: center;

  color: rgb(63, 69, 122);
  text-decoration: none;

  margin: 1.5rem;
  margin-top: 12rem;
  user-select: none;

  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 265px;
  z-index: 200;
}
.positionSticky{
  position: sticky;
  position: -webkit-sticky; /* Safari */
}
.parentBulletContainer{
  border-radius: 3px;

}
.bigRow{
  width: 100%;
  background-color: white;
}

.EachBulletObject{
  width: 100%;

  position: sticky;
  position: -webkit-sticky; /* Safari */
  /* top: 15.5rem; */
  top: 6.5rem;

  background-color: white;
  padding: 0;
  margin: 0;
}

.bulletContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 2.8rem 2rem;
  user-select: none;

  height: auto;
  z-index: 115;
}
.bullet {
  width: 60%;
  text-align: left;
  font-size: 1.2vw;
  font-weight: 200;

  padding: 1.1rem;

  align-self: flex-end;

  list-style-position: outside;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.4em;

  background-color: transparent;
}

/* 
    DOODLE SECTION
*/
.doodleSection {
  z-index: -1400;
  width: 100vw;
  min-height: 40vh;

  padding-top: 5rem;
  margin-top: 8rem;

  margin-bottom: 40rem;
  background-color: transparent;
}
.boldPara {
  font-weight: 700;
  font-size: 5vw;
  font-family: "Architects Daughter", cursive;
}
.spanPara {
  font-weight: 400;
  font-size: 4.8vw;
}
.doodleSectionStyles {
  background-color: rgb(191, 191, 183);
}
.doodleContainer {
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: none !important;
}
.LMrodalSection {
  width: 100vw;
  height: 100vh;
  /* z-index: 120; */
  position: fixed;

  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
  z-index: -200;
}
.bottomText{
  position: relative;
  bottom: -500px;
}

@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oleo+Script&family=Poppins:wght@300&family=Roboto:wght@100;400&display=swap");

/* .content {
  text-align: justify;
  line-height: 55px;
  font-family: "Architects Daughter", cursive;
  font-size: 2.5vw;
  letter-spacing: 1px;
  word-spacing: 5px;
} */

.doodleFSactive {
  /* height: inherit; */
  opacity: 1;
  visibility: visible;
  z-index: 1400;

  /* padding: 4.5rem 0rem; */
  padding-top: 5rem;
  padding-left: 1.3rem;
  height: 100%;
  background-image: repeating-linear-gradient(#FEF4D4 0px, #FEF4D4 24px, teal 25px);

  cursor: text;
  /* cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 512 512' style='enable-background:new 0 0 512.011 512.011;' xml:space='preserve'%3E %3Cpath fill='DeepSkyBlue' d='M434.215,344.467L92.881,3.134c-4.16-4.171-10.914-4.179-15.085-0.019  c-2.011,2.006-3.139,4.731-3.134,7.571v490.667c0.003,4.382,2.685,8.316,6.763,9.92c4.081,1.603,8.727,0.545,11.712-2.667  l135.509-145.92h198.016c5.891,0.011,10.675-4.757,10.686-10.648C437.353,349.198,436.226,346.473,434.215,344.467z'/%3E %3C/svg%3E"), pointer; */
}

::selection {
  color: white;
  background: black;
}


#doodletarget {
  position: relative;
  color: red;
}
#doodletarget::before {
  content: "";
  display: block;
  padding-top: 60%;

  width: 5rem;
  height: 5rem;
  background-image: url('../../public/doodletarget.png');
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -110%;
  left: -13%;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/* P H O N E  */
@media only screen and (max-width: 600px) {
  .content {
    line-height: 29px;
    font-family: "Architects Daughter", cursive;
    font-size: 5.5vw;
    letter-spacing: 1px;
    word-spacing: 5px;
  }
  .doodleFSactive {
    opacity: 1;
    visibility: visible;
    z-index: inherit;
  
    /* padding: 1rem 1.8rem; */
    padding-top: 1.5rem;
    padding-left: 1rem;
    height: 100%;
    background-image: repeating-linear-gradient(#FEF4D4 0px, #FEF4D4 24px, teal 25px);
  
    cursor: text;
    /* cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 512 512' style='enable-background:new 0 0 512.011 512.011;' xml:space='preserve'%3E %3Cpath fill='DeepSkyBlue' d='M434.215,344.467L92.881,3.134c-4.16-4.171-10.914-4.179-15.085-0.019  c-2.011,2.006-3.139,4.731-3.134,7.571v490.667c0.003,4.382,2.685,8.316,6.763,9.92c4.081,1.603,8.727,0.545,11.712-2.667  l135.509-145.92h198.016c5.891,0.011,10.675-4.757,10.686-10.648C437.353,349.198,436.226,346.473,434.215,344.467z'/%3E %3C/svg%3E"), pointer; */
  }

  .learnMoreParent {
    font-size: 1.6rem;
  }
  .cursor {
    display: none;
  }

  .sfulogo {
    width: 5rem;
    padding: 0;
    margin: 0;
  }
  .bulletContainer {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 1rem;

    position: sticky;
    position: -webkit-sticky;
    top: 5.4rem;
    z-index: 115;

    height: auto;
  }
  .bullet {
    width: 60%;
    text-align: left;
    font-size: 3.2vw;
    font-weight: 200;
    padding: 0.8rem;

    align-self: flex-end;
  }

  .jobTitle {
    user-select: none;
    text-align: left;
    font-size: 5.6vw;
  
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top:101px; /* cuz 104= 6.5rem (navbar) */
  
    width: 100%;
  
    height: 83px;
    z-index: 119;
    background-color: transparent;
    opacity: 1;
    
    /* border: 3px solid red !important; */
    padding-left: 0.4rem;

    
  }
  .companyRow{
    position: sticky;
    position: -webkit-sticky; 
    top: 116px;
    width: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
    height: 4rem;
    padding-top: 1rem;
    z-index: 119;
  }

  .company {
    font-size: 4.4vw;
    font-weight: 300;
    width: 100%;
    text-align: center;
  
    color: rgb(63, 69, 122);
    text-decoration: none;
  
    margin: 1.5rem;
    user-select: none;

    z-index: 119;
  }

  .educationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(206, 206, 206);
    min-height: 45vh;
    z-index: 115;
  }
  .educationContainer > .company {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(206, 206, 206);
    z-index: 115;

    position: sticky;
    position: -webkit-sticky; 
    top: 126px;
  }
  .bulletContainer.eduBullet{
    min-height: 0;
    height: 25vh !important;
  }

}

/* T A B L E T */
@media only screen and (min-width: 601px) and (max-width: 1420px) {
  .bulletContainer {
    display: flex;
    flex-direction: column;
  
    width: 100%;
    padding: 2rem;
    user-select: none;
  
    min-height: 0vh;
    height: auto;
    z-index: 115;
  }

  .jobTitle {
    user-select: none;
    text-align: left;
    font-size: 5.6vw;
  
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top:104px; /* cuz 104= 6.5rem (navbar) */
  
    width: 100%;
  
    height: 83px;
    z-index: 119;
    background-color: white ;
    opacity: 1;
    
  }
  .companyRow{
    position: sticky;
    position: -webkit-sticky; 
    top: 185px;
    width: 100%;
  
    background-color: white !important;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
    height: 4rem;
    padding-top: 1rem;
    z-index: 119;

  }

  .company {
    font-size: 4.4vw;
    font-weight: 300;
    width: 100%;
    text-align: center;
  
    color: rgb(63, 69, 122);
    text-decoration: none;
  
    margin: 1.5rem;
    user-select: none;

    z-index: 119;

  }
  .educationContainer > .company {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(206, 206, 206);
    z-index: 115;

    position: sticky;
    position: -webkit-sticky; 
    top: 175px;
  }
}