@import url("https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap");

:root {
  --main-white-color: rgb(239, 239, 239);
  --content-font-family: HelveticaNowText-Light;
  --large-title: 2.3vw;
  --content-font-size: 1.8vw;
  --content-font-size-phone-view: 3.4vw;
}


.second-section {
  background-color: var(--main-white-color);

  color: black;
  height: 100%;
  width: 100%;

  /* display: flex;
  justify-content: center;
  align-items: center; */

  position: relative;
}
.bold {
  font-weight: 800 !important;
  text-decoration: underline;
}
/* .scrollToHere{ 
  position: relative;
  top: 50px;
  left: 0;
  border: 3px solid red !important;
  background-color: red;

  width: 80px;
  height: 80px;
} */

.underline-animation {
  text-decoration: none;
  position: relative;
}
.underline-animation svg {
  display: inline-block !important;
  position: absolute;
  top: 85%;
  left: 0;
  width: 100%;
  height: auto;
}

.underline-animation .strokeWhite {
  stroke: rgb(255, 255, 255);
  stroke-width: 0.065em;
  stroke-dasharray: 822;
  stroke-dashoffset: 822;
  animation: underlineAnimation 0.88s 0.65s cubic-bezier(0.17, 0.55, 0.55, 1)
    forwards;
  animation-play-state: paused;
}
.underline-animation.in-view .strokeWhite {
  animation-play-state: running; /* Start animation when in view */
}

@keyframes underlineAnimation {
  0% {
    stroke-dashoffset: 822;
  }
  100% {
    stroke-dashoffset: 465;
  }
}

.dark-section {
  user-select: none;
  width: 100vw;
  min-height: 100vh;
  cursor: none;
  background-color: black;
  position: relative;

  color: var(--main-white-color);

  /* padding-top: 5%;
  padding-bottom: 5%; */
}
.projectsBanner {
  position: sticky;
  position: -webkit-sticky;
  top: 104px;
}
.projectsContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0rem;

  /* border: 2px solid orange; */
  transform: translateY(-12%);
}

.coloredBtn {
  width: 11rem;
  height: 3.6rem;

  margin: 3rem;

  cursor: none;
  font-family: var(--content-font-family);

  border-radius: 1rem;
  font-size: 1rem;
  color: var(--main-white-color);

  border: 1px solid transparent;
  transition: all 0.5s ease;
  background-color: black;
}
.btnConainter {
  width: 100%;
  text-align: center;
}

.clicked_coloredBtn {
  width: 11rem;
  height: 3.6rem;

  margin: 3rem;

  cursor: none;
  font-family: var(--content-font-family);

  border-radius: 1rem;
  font-size: 1rem;
  color: var(--main-white-color);

  border: 1px solid transparent;
  transition: all 0.5s ease;
  background-color: grey;
}

.learnMoreBtn {
  width: 11rem;
  height: 3.6rem;

  cursor: none;
  font-family: var(--content-font-family);

  border-radius: 1rem;
  margin: 2rem;
  font-size: 1rem;
  background-color: var(--main-white-color);
  color: black;

  border: 1px solid transparent;
  transition: all 0.5s ease;
}

.clicked_learnMoreBtn {
  width: 11rem;
  height: 3.6rem;

  cursor: none;
  font-family: var(--content-font-family);

  border-radius: 1rem;
  margin: 2rem;
  font-size: 1rem;
  background-color: grey;
  color: black;

  border: 1px solid transparent;
}

.coloredBtn:hover {
  transform: scale(1.1);
}
.learnMoreBtn:hover {
  transform: scale(1.1) !important;
  border-color: black;
  box-shadow: 0px 0px 50px 30px rgba(223, 223, 223, 0.2);
}

.finalBtn {
  background-color: rgb(188, 17, 17);
  margin-top: 12rem;
  filter: blur(4px);
}
.finalBtn:hover {
  filter: blur(0px);
}

.greetingsTitle {
  padding-top: 2rem;
  font-family: "Oleo Script", cursive;
  font-weight: 100;
  text-align: center;
}

.shaky-question {
  display: inline-block;
  font-size: 2rem; /* Adjust size as needed */
  font-weight: bold;
  animation: shake 1s infinite;
  transform-origin: bottom center; /* Anchor at the bottom */
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oleo+Script&family=Poppins:wght@300&family=Roboto:wght@100;400&display=swap");
.largeText {
  font-family: "Poppins", sans-serif;
}

.projectsSubhead {
  margin: 1rem 0;
}

.about-section {
  height: auto;
  width: 100vw;

  user-select: none;
  /* padding-top: 10%; */

  position: relative;
}

#aboutSectionBorderBox {
  margin-top: 8rem;
}

.container {
  margin: auto;
  width: 75%;
  text-align: center;
}

.section-banner {
  position: sticky;
  position: -webkit-sticky;
  top: 104px;

  /* border: 2px solid red;   */

  z-index: 119;
  pointer-events: none;
}

.about-title {
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: var(--large-title);
  letter-spacing: 0.1rem;
  font-family: var(--content-font-family);
  font-size: var(--content-font-size);
  font-weight: 600;

  /* position: absolute;
  top: 0%;
  left: 0%;
  transform: translateX(-50%) translateY(-170%); */
}

.ribbon {
  /* margin: 0; */
  /* margin-top: -5%; */
  width: 0px;
  min-height: 18rem;
  background-color: transparent;

  border: solid 35px #000000;
  border-bottom: solid 15px transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  cursor: none;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-filter: drop-shadow(-2px 3px 14px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(-2px 3px 14px rgba(0, 0, 0, 0.4));

  position: sticky;
  position: -webkit-sticky;
  top: 104px;
  left: 100px;
}

.ribbon-dark {
  color: var(--main-white-color);

  position: sticky;
  position: -webkit-sticky;

  top: 104px;
  padding-bottom: 1.8rem;
}

.ribbon-light {
  color: black;

  border: solid 35px var(--main-white-color);
  border-bottom: solid 15px transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  -webkit-filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));
  filter: drop-shadow(-2px 3px 14px rgba(252, 252, 252, 0.4));

  position: sticky;
  position: -webkit-sticky;
  top: 104px;
  padding-bottom: 1.8rem;
}

/* ----------
---CARDS--
---------- */
.cardsContainer {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
}

/* all classes outside media target small screens (below 600px width )*/
.card {
  padding-bottom: 2.8rem;
  position: relative;
  width: 9em;
  /* height: 18rem; */
  height: auto;
  background: black;
  border: 0.4px solid rgb(165, 165, 165);

  overflow: hidden;

  border-radius: 1em;
  margin: 1em 0rem;
  overflow: hidden;
  cursor: none;

  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font: var(--content-font-family);
  font-size: 3.8vw;

  /* 
  z-index: 30 !important; */
}

.card:hover {
  background: rgb(12, 12, 12, 0.6);
  border: 2px solid rgb(122, 122, 122);
  box-shadow: -15px 13px 34px -15px rgba(255, 255, 255, 0.39);
  -webkit-box-shadow: -15px 13px 34px -15px rgba(255, 255, 255, 0.39);
  transform: scale(1.03) translateY(-8px);
  backdrop-filter: blur(65px);
  z-index: 30 !important;
}

@keyframes autoSlide {
  0% {
    transform: translateX(13%);
  }
  50% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(13%);
  }
}
.delay-0s {
  animation-delay: 1s !important;
  /* background-color: orange; */
}
.delay-1s {
  animation-delay: 1.5s !important;
  /* background-color: blue; */
}
.delay-2s {
  animation-delay: 0.25s !important;
}

.iconContainer:hover {
  animation-play-state: paused;
}

.iconContainer {
  margin-left: 1rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 30%;
  width: 100%;

  animation: autoSlide 4.3s cubic-bezier(0.35, 0, 0.65, 1) infinite forwards;
}

.icon {
  padding: 1.4rem 0.5rem;

  max-width: 6rem;
  max-height: 100%;
}

.icon img {
  max-width: 100%;
  max-height: 100%;

  max-inline-size: 100%;
  block-size: auto;
  aspect-ratio: auto 1/1;

  object-fit: scale-down;
  object-position: center;
}

.cardTitle {
  width: 100%;
  text-align: start;
  font-size: 1.6vw;
  font-family: var(--content-font-family);
  font-weight: 600;
  padding: 1rem 1rem;

  letter-spacing: 1.1px;

  background-color: rgb(128, 128, 128, 0.5);
}

.cardDes {
  width: 100%;
  text-align: start;
  padding: 1.3rem 1.3rem;
  font-size: 1.1vw;

  margin-bottom: 1rem;
}

/* Interests Section */
.interestSection {
  user-select: none;
  cursor: none;
  width: 100vw;
  /* min-height: 80vh; */
  background: rgb(239, 239, 239);

  position: relative;
  color: black;

  /* padding-top: 7%; */
}
#interestBox {
  transform: translateY(-25%);
}
.stroke-svg {
  max-height: 15rem;
}
.stroke-svg > svg {
  height: 100%;
  max-height: 10rem;
  border: 2px solid rgb(255, 255, 255);
  margin: 0 auto;
}
.svg-1 {
  border: 4px solid red;
}

.me-box {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
}
.svg-underline {
  position: absolute;
  max-width: 4rem;
  max-height: 1.2rem;

  top: 50%;
}

.svg-underline > svg {
  width: 100%;
  height: 100%;
  margin-top: -8rem;
}

/* Touch Section */
.touchSection {
  padding: 6rem;
  overflow: hidden;
  width: auto;
  margin: auto;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hover-objects {
  color: rgb(123, 123, 123, 0.5);
  filter: blur(4px);
  width: fit-content;
  min-height: 5rem;
  margin: 3rem 0rem;

  transition: 0.4s all ease-in;
  transform: translateY(0px);
}

.hover-objects:hover {
  filter: blur(0px);
  transform: translateY(20px) scale(1.1);
  color: black;
}

.invisibleRef {
  height: 0px;
  width: 100%;
}

.AwshafContent {
  display: inline-block !important;
  line-height: 29px;
  font-family: "Architects Daughter", cursive;
  font-size: 5.5vw;
  letter-spacing: 1px;
  word-spacing: 5px;
  /* border: 2px solid green; */
  /* margin-top: 40px; */
  max-width: 40%;
}
.AwshafContent > svg {
  display: inline-block !important;
  /* background-color: orange; */
  transform: translateY(8px);
}
/***************************

   Interest Section ends here

***************************/



/* LARGE screens */
@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .card {
    position: relative;
    width: 40%;
    height: 22rem;
    background: black;
    border: 0.4px solid rgb(165, 165, 165);

    overflow: hidden;

    border-radius: 1em;
    margin: 1em;
    overflow: hidden;
    cursor: none;

    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
      0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font: var(--content-font-family);
    font-size: var(--content-font-size-phone-view);

    z-index: 30;
  }

  .cardDes {
    width: 100%;
    text-align: start;
    padding: 2rem;
    font-size: 1.1vw;
  }
}
/* LARGE screens */
@media only screen and (min-width: 1701px) and (max-width: 3500px) {
  .card {
    position: relative;
    width: 40%;
    height: 28rem;
    background: black;
    border: 0.4px solid rgb(165, 165, 165);

    overflow: hidden;

    border-radius: 1em;
    margin: 1em;
    overflow: hidden;
    cursor: none;

    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
      0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font: var(--content-font-family);
    font-size: var(--content-font-size-phone-view);
    z-index: 30;
  }

  .cardDes {
    width: 100%;
    text-align: start;
    padding: 2rem;
    font-size: 1.1vw;
  }
}

/* MID screens */
@media only screen and (min-width: 740px) and (max-width: 1399px) {
  .card {
    position: relative;
    width: 40%;
    height: 22rem;
    background: black;
    border: 0.4px solid rgb(165, 165, 165);

    overflow: hidden;

    border-radius: 1em;
    margin: 1em;
    overflow: hidden;
    cursor: none;

    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
      0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font: var(--content-font-family);
    font-size: var(--content-font-size-phone-view);

    z-index: 30;
  }
  .cardDes {
    font-size: 1.3vw;
  }
}

/* SM screens */
@media only screen and (max-width: 600px) {
  .container {
    margin: auto;
    width: 85%;
    text-align: center;
  }

  .projectsContent {
    padding-top: 4rem;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    /* border: 2px solid red; */
    transform: translateY(0%);
  }
  .greetingsTitle {
    padding-top: 0rem;
    font-family: "Oleo Script", cursive;
    font-weight: 100;
    text-align: center;
  }

  .btnConainter {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  .btnConainter > button {
    /* background-color: orange; */
    width: 100%;
  }
  .projectsSubhead {
    margin: 1rem 0;
  }

  .about-section {
    margin-bottom: -6rem;
  }

  .dark-section {
    padding-bottom: 10%;
  }
  .card {
    width: 18em;
    height: auto;
    padding-bottom: 0rem;
  }
  .cardTitle {
    margin: 0;
    padding: 0;

    background-color: rgb(128, 128, 128, 0.5);

    font-size: 4.2vw;
    font-family: var(--content-font-family);
    font-weight: 600;
    padding: 1rem 1rem;
  }
  .cardDes {
    font-size: 3vw;
    margin-bottom: 0;
  }

  .vertical-line {
    height: 25vh;
  }

  /*
  *
  *
  Ribbon
  *
  *
  */
  .projectsBanner {
    position: sticky;
    position: -webkit-sticky;
    top: 104px;
  }

  .section-banner {
    position: sticky;
    position: -webkit-sticky;
    top: 104px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 2px solid greenyellow; */

    transform: translateY(-150%);
    /* margin-bottom: -15rem; */
  }

  .about-title {
    writing-mode: horizontal-tb;
    text-orientation: unset;
    letter-spacing: 0.3rem;
    font-family: var(--content-font-family);
    font-size: var(--content-font-size-phone-view);
    font-weight: 600;

    position: absolute;
    top: 0%;
    left: 0%;
    transform: translateX(-50%) translateY(-170%);
  }

  .ribbon {
    width: 0px;
    min-height: 2.3rem;
    background-color: transparent;

    border: solid 55px #000000;
    border-bottom: solid 15px transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2vw;
    position: relative;
  }

  .ribbon-light {
    border: solid 55px #ffffff;
    border-bottom: solid 15px transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    -webkit-filter: drop-shadow(-2px 3px 14px rgba(250, 250, 250, 0.4));
    filter: drop-shadow(-2px 3px 14px rgba(250, 250, 250, 0.4));

    padding-bottom: 0rem;
  }
  .ribbon-dark {
    padding-bottom: 0rem;
  }

  #interestBox {
    transform: translateY(0%);
  }

  .AwshafContent > svg {
    display: inline-block !important;
    transform: translateY(0px);
  }
}
