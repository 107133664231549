* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.box-container {
  display: flex;
  flex-direction: column;
  min-height: 200vh; /* Make the container taller to see the scroll effect */
}

.box {
  height: 100px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
}

.green { background-color: green; }
.purple { background-color: purple; }
.orange { background-color: orange; }
.red { background-color: red; }
.blue { background-color: blue; }
